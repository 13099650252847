import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://oss.520ai.tech/mini.jpg"
          className="App-logo"
          alt="logo"
        />

        <a
          className="App-link"
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          雪蜜儿美妆
        </a>
        <div className="ad">
          本站由
          <img src="https://oss.520ai.tech/homepage/%E5%8F%88%E6%8B%8D%E4%BA%91_logo2.png" width={80} />
          驱动加速，火力全开~
        </div>
      </header>
    </div>
  );
}

export default App;
